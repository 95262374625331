<template>
	<div class="container-fluid animatedParent animateOnce my-3">
		<!-- main content -->
		<div class="tab-content">
			<div class="tab-pane animated fadeInUpShort show active go">
				
				<!-- <div class="alert alert-danger alert-dismissible" role="alert">
					<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span>
					</button>
					<strong>通知：</strong> XXX。
				</div> -->
				
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">
								
								<div class="row">
									<div class="col-md-9">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">

											<!-- <date-picker class="ml-2" v-model="start_date" format="YYYY-MM-DD" value-type="format"
												input-class="form-control" :editable="false" :clearable="true" placeholder="有效期开始时间">
											</date-picker>
											<div class="ml-2">
												<date-picker v-model="end_date" format="YYYY-MM-DD" value-type="format"
													input-class="form-control" :editable="false" :clearable="true" placeholder="有效期结束时间">
												</date-picker>
											</div> -->

											<span v-if="user_role== 'ADMIN'">
												<select v-model="dept_id" class="form-control  ml-2">
													<option value="0">所有企业</option>
													<option v-bind:key="index" 
														:value="item.id" v-for="(item,index) in departmentlist">
														{{item.department_name}}</option>
												</select>
											</span>

											<select v-model="category" class="form-control ml-2">
												<option value="">所有福利分类</option>
												<option v-bind:key="index"
													:value="item.value" v-for="(item,index) in categorylist">
													{{item.name}}</option>
											</select>

											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i
													class="icon-search"></i>
											</button>
											
											<!-- <button @click="search" type="button" class="btn btn-xs btn-link  ml-2">
												查看过期制度
											</button> -->
											<!-- <div class="form-check ml-2">
												<label class="form-check-label">
													<input 
													v-model="expired" 
													class="form-check-input" type="checkbox"> 包括过期失效制度
												</label>
											</div> -->
										</div>
										<!-- 搜索 end -->
									</div>
									<div class="col-md-3">
										<div class="float-right m-3">
											<button 
											v-if="user_role== 'ADMIN'"
											@click="add" type="button" class="btn btn-secondary">创建制度</button>
										</div>
									</div>
								</div>
							</div>

							<div class="table-responsive">
								<div v-if="institutionlist.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<th style="width: 60px">
													<!-- <div class="custom-control custom-checkbox">
														<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
													</div> -->
													序号
												</th>
												<th>
													<div class="d-none d-lg-block">福利分类</div>
												</th>
												<th v-if="user_role== 'ADMIN'">
													<div class="d-none d-lg-block">企业</div>
												</th>
												<th>
													<div class="d-none d-lg-block">制度名称</div>
												</th>
												<!-- <th>
													<div class="d-none d-lg-block">场景类型</div>
												</th> -->
												<th>
													<div class="d-none d-lg-block">费用类型</div>
												</th>
												<th>
													<div class="d-none d-lg-block">有效期</div>
												</th>
												<!-- <th>
													<div class="d-none d-lg-block">消费模式</div>
												</th> -->
												
												<th>
													<div class="d-none d-lg-block">金额</div>
												</th>
												
												<th>
													<div class="d-none d-lg-block">备注</div>
												</th>
												
												<!-- <th>
													<div class="d-none d-lg-block">转赠</div>
												</th> -->
												<th>
													<div class="d-none d-lg-block">状态</div>
												</th>
												<th>
													<div class="d-none d-lg-block">创建时间</div>
												</th>
												<th></th>
											</tr>
										</thead>

										<tbody>
											<tr v-bind:key="index" v-for="(item,index) in institutionlist"
												:class="{'blue lighten-5':selectedIndex == index}">
												<td>
													{{index+1}}
													<!-- <label class="custom-control custom-checkbox">
														<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
													</label> -->
												</td>
												<td>
													<div class="d-none d-lg-block">
													<!-- {{item.category}} -->
													<template v-if="categoryMap.hasOwnProperty(item.category)">
														{{categoryMap[item.category]}}
													</template>
													</div>
												</td>
												<td v-if="user_role== 'ADMIN'">
													<div class="d-none d-lg-block">
													<!-- {{item.dept_id}} -->
													<template v-if="deptmap.hasOwnProperty(item.dept_id)">
														{{deptmap[item.dept_id].department_name}}
													</template>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
													{{item.institution_name}}
													<br>
													{{item.institution_id}}
													<!-- <i class="icon icon-copy" /> -->
													</div>
												</td>

												<!-- <td>
													<div class="d-none d-lg-block">
														<span class="font-weight-bold">{{item.scene_type}}</span>
													</div>
												</td> -->

												
												<td>
													<div class="d-none d-lg-block">
														{{expenseTypeMap[item.expense_type]}}
														>> 
														<small class="text-muted">{{subexpenseTypeMap[item.expense_type_sub_category]}}</small>
														
													</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">
													{{item.effective_start_date}}
													~
													{{item.effective_end_date=='2222-01-01' || item.effective_end_date=='2099-12-31'?'长期':item.effective_end_date}}
													</div>
												</td>
												
												<!-- <td>
													<div class="d-none d-lg-block">
													{{item.consume_mode}}
													</div>
												</td> -->
												
												

												<td>
													<div class="d-none d-lg-block"><span
															class="r-3  font-weight-bold">
															{{item.quota_total}}
															元
															</span>
													
													<!-- <button 
													@click.stop="refresh(index)" type="button"
														class="btn btn-link btn-xs"> <i class="icon-refresh"></i> </button> -->
													</div>
												</td>
												
												<td>
													
													<div 
													v-if="item.remark" 
													
													v-tooltip.top="''+item.remark+''"
													
													class="d-none d-lg-block text-ellipsis" style="width: 100px;">
													{{item.remark}} 
													</div>
													<!-- <button
													@click.stop="updateRemarkReady(index)" type="button"
														class="btn btn-link btn-xs"> <i class="icon-edit"></i> </button>
													 -->
												</td>
												
												
												
												<!-- <td>
													<div class="d-none d-lg-block">
													{{item.share_mode==1?'允许':'不允许'}} 
													</div>
												</td> -->
												
												<td>
													<div class="d-none d-lg-block">
													<span :class="{'text-success':item.effective==1,'text-danger':item.effective==0}">{{item.effective==1?'启用':'停用'}}</span>
													</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">{{item.create_date}}</div>
												</td>
												
												<td class="form-inline pt-4">
													<!-- <button @click.stop="view(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-2"> 详情 </button> -->
													<!-- <button v-if="item.enabled==0"
														@click.stop="launch(index,1)" type="button"
														class="btn btn-outline-primary btn-xs ml-1"> 投放 </button>	
													<button v-if="item.enabled==1" 
														@click.stop="launch(index,0)" type="button"
														class="btn btn-outline-primary btn-xs ml-1"> 停止投放 </button>	 -->	
													
													<!-- <button
														@click.stop="updateBudgetReady(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-1"> 发放记录 </button>	
													 -->
													 
													 <button
													 	@click.stop="view(index)" type="button"
													 	class="btn btn-outline-primary btn-xs ml-1"> 使用条件 </button>
													 
													 
													<button
														v-if="user_role== 'USER'"
														@click.stop="issueReady(index)" type="button" 
														:disabled="item.effective==0" 
														class="btn btn-outline-primary btn-xs ml-1"> 发放 </button>
														
														<button
															v-if="user_role== 'USER'"
															@click.stop="importIssueReady(index)" type="button" 
															:disabled="item.effective==0" 
															class="btn btn-outline-primary btn-xs ml-1"> 批量发放 </button>
													
													
													<template v-if="user_role== 'ADMIN'">
														<button
															@click.stop="updateRemarkReady(index)" type="button"
															class="btn btn-outline-primary btn-xs ml-1"> 备注 </button>
														
													<button
														@click.stop="updateInfoReady(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-1"> 编辑 </button>
														
													<button
														v-if="item.expense_type_sub_category=='REACH_SHOP'"
														@click.stop="updateMccReady(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-1"> 修改MCC </button>	
													
													</template>
													
													<!-- <div class="dropdown">
													  <button type="button" class="btn btn-link ml-2"  role="button" id="" data-toggle="dropdown" aria-expanded="false">
													   <i class="icon-more_horiz"></i>	
													  </button>
													  <div class="dropdown-menu" aria-labelledby="">
													    <span @click.stop="launch(index,1)" class="dropdown-item" >投放</span>
													    <span @click.stop="launch(index,0)" class="dropdown-item" >停止投放</span>
													  </div>
													</div> -->
													
												</td>
											</tr>
										</tbody>
										
									</table>
								</div>
								<div v-if="institutionlist.length == 0">
									<div class="card-body text-lg-center m-5 height-300">
										<h5 class="mb-3">没有制度</h5>
										<!-- <button @click="add" type="button" class="btn btn-lg btn-secondary">
											创建制度
										</button> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if="institutionlist.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">

						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>

					</div>
				</div>

			</div>

		</div>
		<!-- //main content -->

		<!-- modal -->
		<!-- 详情 modal -->
		<div class="modal fade" id="dModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">使用条件</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
		
						<div class="m-3">
							<div class="form-group">
								<label for="">制度名称</label>
								<input v-model="institution.institution_name" type="text" class="form-control-plaintext"
									placeholder="输入制度名称">
								<div class="s-12">
								</div>
							</div>
							
							<div class="form-group">
								<label for="">使用条件</label>
								
								<template v-for="(item, index) in standard_condition_info_list">
									<div v-bind:key="index" class="mb-2">
										<span class="s-12 font-weight-bold">
										
										<template v-if="ruleKeyMap.hasOwnProperty(item.rule_factor)">
											{{ruleKeyMap[item.rule_factor]}}
											<!-- /{{item.rule_factor}} -->
										</template>
										<template v-else>{{item.rule_factor}}</template>
										
										:</span>
										<span class="s-12 text-black">
											<!-- {{item.rule_value}} -->
											<template v-if="item.rule_factor=='AREA'">
												{{item.rule_value.mode=='notLimit'?'不限':''}}
											</template>
											<template v-if="item.rule_factor=='MEAL_MERCHANT'">
												{{item.rule_value}}
												<!-- <template v-for="(value, key) in item.rule_value">
													<span v-bind:key="key">
													pid: {{key}}<br>
													蚂蚁门店ID: {{value}}
													</span>
												</template> -->
											</template>
											<template v-if="item.rule_factor=='QUOTA_TOTAL'">
												{{item.rule_value}}元
											</template>
											
											<template v-if="item.rule_factor=='MCC'">
												{{item.rule_value}}
											</template>
											
											<template v-if="item.rule_factor=='SUPPLIER_CHANNEL'">
												饿了么
											</template>
											
											<template v-if="item.rule_factor=='SHIPPING_ADDRESS'">
												不限
											</template>
											
											<template v-if="item.rule_factor=='ELM_MERCHANT'">
												{{item.rule_value}}
											</template>
											
											<template v-if="item.rule_factor=='BOOK_SWITCH'">
												关闭
											</template>
											
											<template v-if="item.rule_factor=='TAKE_AWAY_CATEGORY'">
												{{item.rule_value}}
											</template>
											
											
										</span>
									</div>
									
								</template>
								
								<div class="s-12">
								</div>
							</div>
		
						</div>
		
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						
					</div>
				</div>
			</div>
		</div>
		<!-- //详情 modal -->

		<!--  基本信息 modal -->
		<div class="modal fade" id="iModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">基本信息</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">
							<div class="form-group">
								<label for="">制度名称</label>
								<input v-model="institution.institution_name" type="text" class="form-control"
									placeholder="输入制度名称">
								<div class="s-12">
									20个字以内
								</div>
							</div>

							<div class="form-group">
								<label for="">有效期开始日期</label>
								<div>
								<date-picker v-model="institution.effective_start_date" format="YYYY-MM-DD" value-type="format"
									input-class="form-control" :editable="false" :clearable="false">
								</date-picker>
								</div>
								<div class="s-12">
									最早是当日
								</div>
							</div>

							<div class="form-group">
								<label for="">有效期结束日期</label>
								<div>
								<template v-if="!isLongTime">
									<date-picker v-model="institution.effective_end_date" format="YYYY-MM-DD" value-type="format"
										input-class="form-control" :editable="false" :clearable="false">
									</date-picker>
								</template>
								<span class="ml-2">
									<input type="checkbox" @change="onChangeLongTime" v-model="isLongTime" value="1" id="checkbox" name="checkbox">
									<label for="checkbox">长期</label>
								</span>	
									
								</div>
								<div class="s-12">
									结束时间不早于起始时间
								</div>
							</div>
							
							<div class="form-group">
								<label for="">启用</label>
								<div class="material-switch">
									<input v-model="institution.effective" value="1" id="e" name="e" type="checkbox">
									<label for="e" class="bg-primary"></label>
								</div>
								
								<div class="s-12">
								</div>
							</div>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						
						<button
							@click.stop="removeReady" type="button"
							class="btn btn-danger ml-1"> 删除 </button>
						
						
						<button
						@click.stop="updateInfo()" :disabled="disabled" 
						type="button" class="btn btn-info"> 修改
						</button>
						
						
					</div>
				</div>
			</div>
		</div>
		<!-- //基本信息 modal -->
		
		<!-- 备注 modal -->
		<div class="modal fade" id="sModal" tabindex="-1" aria-labelledby="aa" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="aa">分类与备注</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
		
						<div class="m-3">
							
							<div class="form-group">
								<label for="">福利分类</label>
								<select class="form-control" v-model="selected_category">
									<option value="">选择福利分类</option>
									<template v-for="(item,index) in categorylist">
									<option v-bind:key="index"
										:value="item.value">
										{{item.name}}</option>
									</template>
								</select>
								<div class="s-12">
								</div>
							</div>
							
							<div class="form-group">
								<label for="">备注</label>
								<input v-model="institution_remark" type="text" class="form-control"
									placeholder="输入制度备注说明">
								<div class="s-12">
								</div>
							</div>
							
						</div>
		
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						
						<button 
						@click.stop="updateRemark()" :disabled="disabled" 
						type="button" class="btn btn-primary" data-dismiss="modal">保存</button>
						
					</div>
				</div>
			</div>
		</div>
		<!-- //备注 modal -->
		
		<!-- 商户类型(到店) -->
		<div class="modal fade" id="mccModal" tabindex="-1" aria-labelledby="mccModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="mccModalLabel">修改商户类型</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div role="alert" class="alert alert-danger">
							<strong>注意事项</strong> 需要指定类目，请取消选中「不限」.
						</div>
						<table class="table">
						  <tbody>
						    <tr v-for="(item,index) in mcc_list" v-bind:key="index">
								<td class="align-top" style="width: 16px;">
									<div v-if="item.show" @click="toggleSubMccList(index)">
										<i class="icon icon-chevron-down text-dark"></i>
									</div>
									<div v-if="!item.show" @click="toggleSubMccList(index)">
										<i class="icon icon-chevron-right text-dark"></i>
									</div>
									</td>
								<td>
									<!-- 一级类目 -->
									<div class="form-check">
										<input 
										@change="onChangeMcc(index)"
										v-model="item.checked" 
										:disabled = "item.disabled"
										class="form-check-input" type="checkbox" :id="'mcc_cb_'+index">
										<label class="form-check-label" :for="'mcc_cb_'+index">
										 {{item.name}}
										</label>
									</div>
									
									<!-- 二级类目 -->
									<div style="margin-left: 20px;" v-show="item.show">
										<template v-for="(subitem,subindex) in item.items">
										<div class="form-check" v-bind:key="subindex" v-if="index == 0">
											<input 
											v-model="subitem.checked" 
											:disabled = "true"
											class="form-check-input" type="checkbox"  :id="'sub_mcc_cb_'+subindex">
											<label class="form-check-label" :for="'sub_mcc_cb_'+subindex">
											 {{subitem.name}}
											</label>
										</div>
										<div class="form-check" v-bind:key="subindex" v-if="index>0">
											<input 
											@change="onChangeSubMcc(index, subindex)"
											v-model="subitem.checked" 
											:disabled = "!item.checked"
											class="form-check-input" type="checkbox"  :id="'sub_mcc_cb_'+subindex">
											<label class="form-check-label" :for="'sub_mcc_cb_'+subindex">
											 {{subitem.name}}
											</label>
										</div>
										</template>
									</div>
								</td>
						    </tr>
						  </tbody>
						</table>
		
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveMcc" :disabled="disabled" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	
	const __STOCKTYPE = {
		'NORMAL': '满减券',
		'DISCOUNT': '折扣券',
		'EXCHANGE': '换购券'
	}
	const __STOCKSTATE = {
		'RUNNING': '运行中',
		'UNAUDIT': '审核中',
		'EXPIRED': '已过期',//自定义状态
	}
	
	const _MCC_LIST = [
		{name: '不限', value: '-1', show: true, checked: true, items: 
			[
				{name: '不限', value: '-1', checked: true},
			]
		},
		{name: '餐饮', value: 'A0001', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '火锅', value: 'B0199'},
				{name: '中式正餐', value: 'B0001'},
				{name: '西式正餐', value: 'B0002'},
				{name: '日韩/东南亚菜', value: 'B0003'},
				{name: '中式快餐', value: 'B0004'},
				{name: '西式快餐', value: 'B0005'},
				{name: '小吃/熟食', value: 'B0006'},
				{name: '校园团餐', value: 'B0007'},
				{name: '综合团餐', value: 'B0008'},
				{name: '饮品/甜品', value: 'B0009'},
				{name: '烘焙糕点', value: 'B0010'},
				{name: '酒吧/清吧', value: 'B0011'},
				{name: '咖啡馆/茶馆', value: 'B0012'},
				{name: '宴会提供商', value: 'B0200'},
				{name: '订餐服务平台', value: 'B0117'},
			]
		},
		{name: '零售批发', value: 'A0002', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '小型商店', value: 'B0013'},
				{name: '连锁便利店', value: 'B0014'},
				{name: '大型超市', value: 'B0015'},
				{name: '大卖场', value: 'B0016'},
				{name: '百货商场', value: 'B0017'},
				{name: '购物中心', value: 'B0018'},
				{name: '奥特莱斯', value: 'B0019'},
				{name: '商业街', value: 'B0020'},
				{name: '水果店', value: 'B0021'},
				{name: '食品零售', value: 'B0201'},
				{name: '蔬菜/肉蛋/水产', value: 'B0202'},
				{name: '茶叶', value: 'B0022'},
				{name: '酒类', value: 'B0023'},
				{name: '烟草/雪茄', value: 'B0024'},
				{name: '营养品/保健品', value: 'B0025'},
				{name: '服饰鞋包', value: 'B0026'},
				{name: '美妆个护', value: 'B0027'},
				{name: '黄金珠宝/钟表饰品', value: 'B0028'},
				{name: '眼镜店', value: 'B0029'},
				{name: '3C数码/家用电器', value: 'B0030'},
				{name: '办公用品', value: 'B0203'},
				{name: '图文音像/工美乐器', value: 'B0031'},
				{name: '母婴用品/儿童玩具', value: 'B0032'},
				{name: '宠物/宠物用品', value: 'B0033'},
				{name: '户外运动器材', value: 'B0034'},
				{name: '五金建材', value: 'B0035'},
				{name: '家居家纺', value: 'B0216'},
				{name: '鲜花/绿植', value: 'B0036'},
				{name: '文物经营/文物复制品', value: 'B0037'},
				{name: '计生用品', value: 'B0038'},
				{name: '烟花爆竹', value: 'B0039'},
				{name: '危险化工产品', value: 'B0040'},
				{name: '石油及石油产品', value: 'B0041'},
				{name: '工业产品', value: 'B0042'},
				{name: '其他专业零售店', value: 'B0043'},
				{name: '互联网综合电商平台', value: 'B0114'},
				{name: '互联网垂直电商平台', value: 'B0115'},
				{name: '社区团购服务平台', value: 'B0118'},
			],
		},
		{name: '商业生活服务', value: 'A0003', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '美发/美容/美甲服务', value: 'B0044'},
				{name: '洗浴/保健养生服务', value: 'B0045'},
				{name: '家政/清洁服务', value: 'B0046'},
				{name: '电器/家具/其他维修', value: 'B0047'},
				{name: '建筑装饰/装修服务', value: 'B0048'},
				{name: '地产/房屋中介代理', value: 'B0049'},
				{name: '宠物医院/其他宠物服务', value: 'B0050'},
				{name: '数码/娱乐设备租赁', value: 'B0051'},
				{name: '无人值守自助服务', value: 'B0052'},
				{name: '共享充电宝及其他共享租赁', value: 'B0212'},
				{name: '无人值守自助零售', value: 'B0213'},
				{name: '无人值守自助娱乐', value: 'B0214'},
				{name: '婚介/婚庆/摄影服务', value: 'B0053'},
				{name: '广告/会展/图文印刷', value: 'B0054'},
				{name: '法律咨询/律师事务所', value: 'B0055'},
				{name: '会计/金融咨询服务', value: 'B0056'},
				{name: '征信和信用报告咨询服务', value: 'B0057'},
				{name: '人才招聘服务', value: 'B0058'},
				{name: '丧葬服务', value: 'B0059'},
				{name: '拍卖/收藏', value: 'B0060'},
				{name: '其他商业及生活服务', value: 'B0061'},
				{name: '默认行业', value: 'B0223'},
				{name: '生活服务平台', value: 'B0116'},
				{name: '在线工具', value: 'B0112'},
				{name: '物业管理', value: 'B0165'},
			],
		},
		{name: '休闲娱乐', value: 'A0004', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '歌舞厅/休闲会所/KTV', value: 'B0062'},
				{name: '棋牌桌游/电玩网吧', value: 'B0063'},
				{name: '健身房/瑜伽/舞蹈', value: 'B0064'},
				{name: '院线影城/演出赛事', value: 'B0065'},
				{name: '游乐园/嘉年华', value: 'B0066'},
				{name: '文化场馆', value: 'B0067'},
				{name: '体育场馆', value: 'B0068'},
				{name: '彩票', value: 'B0069'},
				{name: '其他文体娱乐', value: 'B0070'},
				{name: '网络社交', value: 'B0108'},
				{name: '网络图书/视频/音乐', value: 'B0109'},
				{name: '游戏', value: 'B0110'},
				{name: '游戏周边服务/交易平台', value: 'B0205'},
				{name: '网络直播', value: 'B0111'},
			],
		},
		{name: '车主生活', value: 'A0005', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '车辆零配件/用品精品', value: 'B0071'},
				{name: '汽车洗美/维修养护', value: 'B0072'},
				{name: '车辆销售', value: 'B0073'},
				{name: '二手车销售', value: 'B0074'},
				{name: '停车服务', value: 'B0075'},
				{name: '代驾服务', value: 'B0076'},
				{name: '电动汽车充换电', value: 'B0077'},
				{name: '两轮电瓶车充换电', value: 'B0078'},
				{name: '汽车租赁', value: 'B0079'},
				{name: 'ETC不停车自动缴费', value: 'B0080'},
				{name: 'MTC半自动车道收费', value: 'B0081'},
				{name: '高速服务区', value: 'B0082'},
				{name: '道路救援', value: 'B0083'},
			],
		},
		{name: '交通出行', value: 'A0006', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '公路客运', value: 'B0084'},
				{name: '出租车客运', value: 'B0085'},
				{name: '网约车客运', value: 'B0086'},
				{name: '地铁', value: 'B0087'},
				{name: '公共交通', value: 'B0088'},
				{name: '共享两轮车服务', value: 'B0089'},
				{name: '加油卡、加油服务', value: 'B0090'},
				{name: '加油站、加气站', value: 'B0091'},
				{name: '航空公司', value: 'B0097'},
				{name: '机场', value: 'B0098'},
				{name: '机票代理人', value: 'B0099'},
				{name: '航空系统商', value: 'B0100'},
				{name: '铁路旅客运输', value: 'B0101'},
				{name: '游轮及巡游航线服务', value: 'B0102'},
			],
		},
		{name: '物流运输', value: 'A0015', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '物流/快递', value: 'B0092'},
				{name: '快递服务（个人商户）', value: 'B0093'},
				{name: '物流平台', value: 'B0217'},
				{name: '同城即时配送', value: 'B0218'},
				{name: '邮政基本服务', value: 'B0094'},
				{name: '驿站/自提柜', value: 'B0095'},
				{name: '仓储', value: 'B0096'},
				{name: '铁路货物运输', value: 'B0103'},
				{name: '船舶、海运服务提供商', value: 'B0104'},
				{name: '货品停放交易（国际专用）', value: 'B0105'},
				{name: '国际货运代理和报关行', value: 'B0106'},
				{name: '其他运输代理业', value: 'B0107'},
			],
		},
		{name: '网络通讯', value: 'A0007', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '信息检索服务/网络论坛', value: 'B0113'},
				{name: '互联网数据服务', value: 'B0120'},
				{name: '软件开发服务', value: 'B0121'},
				{name: '数字类产品-游戏（国际专用）', value: 'B0122'},
				{name: '数字类产品-软件提供商（国际专用）', value: 'B0123'},
				{name: '数字类产品-其他（国际专用）', value: 'B0124'},
				{name: '其他在线应用或综合类服务', value: 'B0125'},
				{name: '网络推广/网络广告', value: 'B0126'},
				{name: '电信运营商', value: 'B0127'},
				{name: '付费电视', value: 'B0128'},
				{name: '网络电话、传真服务', value: 'B0129'},
			],
		},
		{name: '教育培训', value: 'A0008', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '儿童保育服务（公立，含学前教育）', value: 'B0130'},
				{name: '儿童保育服务（民办，含学前教育）', value: 'B0131'},
				{name: '中小学校（公立）', value: 'B0132'},
				{name: '中小学校（民办）', value: 'B0133'},
				{name: '大学与学院（公立）', value: 'B0134'},
				{name: '大学与学院（民办）', value: 'B0135'},
				{name: '其他学校/公立培训机构', value: 'B0136'},
				{name: '教育管理机构', value: 'B0215'},
				{name: '教育培训机构', value: 'B0137'},
				{name: '少年宫及青少年发展中心', value: 'B0138'},
				{name: '其他教育/培训', value: 'B0139'},
			],
		},
		{name: '医疗卫生', value: 'A0009', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '医药销售', value: 'B0140'},
				{name: '医疗器械销售', value: 'B0141'},
				{name: '保健辅助治疗器材', value: 'B0142'},
				{name: '公立医院', value: 'B0143'},
				{name: '社区卫生服务中心', value: 'B0144'},
				{name: '专业公共卫生机构', value: 'B0145'},
				{name: '民营医院', value: 'B0146'},
				{name: '诊所', value: 'B0147'},
				{name: '个体医生', value: 'B0148'},
				{name: '医疗美容', value: 'B0149'},
				{name: '眼科医疗服务', value: 'B0150'},
				{name: '口腔医疗服务', value: 'B0151'},
				{name: '医学实验室及诊断中心', value: 'B0152'},
				{name: '线上医疗服务', value: 'B0153'},
				{name: '护理机构服务', value: 'B0154'},
				{name: '体检/健康咨询', value: 'B0155'},
				{name: '其他医疗保健服务', value: 'B0156'},
			],
		},
		{name: '酒旅景区', value: 'A0010', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '酒店/旅馆/民宿', value: 'B0157'},
				{name: '景区', value: 'B0158'},
				{name: '旅行社和旅游服务', value: 'B0159'},
				{name: '旅游相关服务直销', value: 'B0160'},
				{name: '风景区系统商', value: 'B0219'},
				{name: '观光车', value: 'B0220'},
				{name: '游船码头', value: 'B0221'},
				{name: '旅游服务平台（OTA）', value: 'B0119'},
			],
		},
		{name: '生活缴费', value: 'A0011', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '公共事业（电、气、水）', value: 'B0161'},
				{name: '公共事业-电力缴费', value: 'B0204'},
				{name: '公共事业-煤气缴费', value: 'B0211'},
				{name: '公共事业-自来水缴费', value: 'B0206'},
				{name: '公共事业-有线电视缴费', value: 'B0162'},
				{name: '公共事业-清洁服务缴费', value: 'B0207'},
				{name: '公共事业-其他缴费', value: 'B0163'},
				{name: '话费充值与缴费', value: 'B0164'},
			],
		},
		{name: '金融理财', value: 'A0012', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '金融机构', value: 'B0166'},
				{name: '金融机构-其他服务', value: 'B0167'},
				{name: '外币汇兑', value: 'B0168'},
				{name: '股票基金服务', value: 'B0169'},
				{name: '小贷公司', value: 'B0170'},
				{name: '借贷消费平台', value: 'B0171'},
				{name: '融资租赁公司', value: 'B0172'},
				{name: '金融租赁公司', value: 'B0173'},
				{name: '汽车金融公司', value: 'B0174'},
				{name: '消费金融公司', value: 'B0175'},
				{name: '信托公司', value: 'B0176'},
				{name: '支付机构', value: 'B0177'},
				{name: '融资担保公司', value: 'B0178'},
				{name: '其他金融服务', value: 'B0179'},
				{name: '保险业务/保险代理', value: 'B0180'},
				{name: '典当', value: 'B0181'},
			],
		},
		{name: '公共管理和社会组织', value: 'A0013', show: false, checked: false, disabled: true,   items: 
			[
				{name: '不限', value: '-1'},
				{name: '政府机构', value: 'B0182'},
				{name: '行政费用和罚款（非税）', value: 'B0183'},
				{name: '公积金', value: 'B0184'},
				{name: '公安交管', value: 'B0185'},
				{name: '医保', value: 'B0186'},
				{name: '社会保障服务', value: 'B0187'},
				{name: '政府采购', value: 'B0188'},
				{name: '文体机构', value: 'B0222'},
				{name: '政府贷款', value: 'B0189'},
				{name: '法庭费用', value: 'B0190'},
				{name: '保释金', value: 'B0191'},
				{name: '税务', value: 'B0192'},
				{name: '慈善和社会公益服务', value: 'B0193'},
				{name: '使领馆', value: 'B0194'},
				{name: '行业协会和专业社团', value: 'B0195'},
				{name: '汽车协会', value: 'B0196'},
				{name: '宗教组织', value: 'B0197'},
			],
		},
	];

	import VPagination from '../components/my-page.vue'
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-cn';
	
		
	export default {
		data() {
			return {
				title: ' 制度列表',
				user_role: '',
				
				loader: {},

				token: '',
				outlet_id: 0,
				outlet_name: '',
				user_name: '',
				outlet_level: 0,

				setting: {},
				outletlist: [],

				query: '',

				current_page: 1,
				page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,

				total: 0, // 记录总条数
				display: 10, // 每页显示条数
				current: 1, // 当前的页数

				selectedIndex: -1,
				institution: {}, //选中的对象

				//////
				start_date: '',
				end_date: '',
				
				disabled: false,
				
				institutionlist: [],
				
				enabled: -1,
				enabled_institutions: 0,//投放数
				expired: false,
				
				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				expenseTypeMap: {
					'DEFAULT': '通用',
					'MEAL':'餐饮',
					// 'MALL':'商城',
					// 'METRO': '公交地铁',
					// 'PREPAYRECHARGE': '话费充值',
					// 'HOTEL': '酒店',
					// 'TRAIN': '火车票',
					// 'PLANE': '机票',
					// 'CAR': '用车',
				},
				subexpenseTypeMap: {
					'DEFAULT': '通用',
					'REACH_SHOP':'到店',
					'TAKE_AWAY':'外卖',
					// 'ONLINE_MALL':'线上商城',
					// 'METRO': '公交地铁',
					// 'ONLINE_PREPAY_RECHARGE': '线上充值',
					// 'REACH_SHOP_HOTEL': '到店住宿',
					// 'ONLINE_HOTEL': '在线订酒店',
					// 'ONLINE_TRAIN': '火车票预订',
					// 'ONLINE_PLANE': '机票预订',
					// 'CAR': '用车',
				},
				ppMap: {
					'COMBINATION': '超额部分由个人支付',
					'PERSONAL': '整单全部由个人支付'
				},
				
				isLongTime: false,
				current_date: '',
				
				share_mode: false,
				
				standard_condition_info_list: [],
				
				institution_remark: '',
				
				////可读化使用条件
				ruleKeyMap:{
					"AREA": "可用位置",
					"MEAL_MERCHANT": "可用商户",//到店可用商户
					"QUOTA_TOTAL": "封顶金额",
					"MCC": "商户类型",//到店商户类型
					"SUPPLIER_CHANNEL": "供应商渠道",
					"ELM_MERCHANT": "可用商户",//外卖可用商户
					"TAKE_AWAY_CATEGORY": "商户类型",//外卖商户类型
					"SHIPPING_ADDRESS": "可用订餐地址",
					"BOOK_SWITCH": "预订",
					"MARKED_WORDS": "订餐说明"
					
					
				},
				//本地分类
				// categorylist: [
				// 	{name: '弹性福利', value: 'DEFAULT'},
				// 	{name: '餐食外卖', value: 'MEAL'},
				// 	{name: '交通出行', value: 'CAR'},
				// 	{name: '工会福利', value: 'COMMON'},//OTHER
				// ],
				categorylist: this.GLOBAL.categorylist,
				selected_category: '',
				
				// categoryMap: {
				// 	"DEFAULT": "弹性福利",
				// 	"MEAL": "餐食外卖",
				// 	"CAR": "交通出行",
				// 	"COMMON": "工会福利",
				// },
				categoryMap: [],
				category: '',
				
				////MCC
				mcc_list: [],
				mcc:{"-1": ["-1"]},//默认MCC
			}

		},
		components: {
			VPagination,
			DatePicker,
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
			self.user_role = lsLoginData.user_role;
			
			//构造categoryMap
			self.categoryMap = [];
			for(let i=0; i<this.GLOBAL.categorylist.length; i++){
				let et = this.GLOBAL.categorylist[i];
				self.categoryMap[et.value] = et.name;
			}
		},

		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//先取预载数据，再取业务数据
			if(self.user_role == 'ADMIN'){
				self.init(() => {
					self.initData();
				})
			}
			else{
				self.initData();
			}
		},
		methods: {

			init(callback) {
				let self = this;
			
				//
				this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.departmentlist = data.data.departments;
							
							for (let i = 0; i < self.departmentlist.length; i++) {
								let dept = self.departmentlist[i];
								self.deptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.deptmap);
							
							self.current_date = data.data.current_date;
							
							if (typeof callback == 'function') {
								callback()
							}
						} 
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							alert(data.message)
						}
					});
				//END
			
			},

			initData() {
				let self = this;
				
				self.loader = self.$loading.show();

				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getInstitutionList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							category: self.category,
							start_date: self.start_date,//有效期，预留
							end_date: self.end_date,
							query: self.query,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						
						self.loader.hide();
						
						if (data.code == 200) {

							self.institutionlist = data.data.institution_list;
							
							self.start_date = data.data.start_date;
							self.end_date = data.data.end_date;

							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)

							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;

							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数

							// self.institutionlist = []; //重置
							// for (let institution of data.data.list) {
							// 	// institution.typeDesc = __STOCKTYPE[institution.stock_type];
							// 	// institution.stateDesc = institution.stock_state?__STOCKSTATE[institution.stock_state]:""
								
							// 	self.institutionlist.push(institution);
							// }
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},

			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initData();
			},

			search() {
				let self = this;

				self.initData();
			},
			
			add(){
				let self = this;
				
				self.$router.push({
				    name: 'addinstitution',
				    params: {
				        outlet_id: self.outlet_id
				    }
				}) 
			},
			
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init(() => {
					self.initData();
					self.employee_id = 0;
				})

			},
			
			view(index) {
				console.log(index);
				let self = this;
			
				let institution = Object.assign({}, self.institutionlist[index]);
				console.log(index, institution);
			
				self.selectedIndex = index;
				self.institution = Object.assign({},institution);
				self.isLongTime = self.institution.effective_end_date=='2222-01-01' || self.institution.effective_end_date=='2099-12-31'	
				
				self.institution.effective_start_date = self.current_date;
				
				let condList = [];
				self.standard_condition_info_list = JSON.parse(self.institution.standard_condition_info_list);
				console.log('--- LEN ->',self.standard_condition_info_list.length)
				for(let i=0; i<self.standard_condition_info_list.length; i++){
					let cond = self.standard_condition_info_list[i];
					console.log('--- cond ->\n')
					console.log(cond)
					console.log(cond.rule_factor+'\n')
					console.log(cond.rule_value+'\n')
					
					// let obj = eval('('+cond.rule_value+')');//转化出错，JSON.parse(cond.rule_value);也一样
					
					condList.push(cond);
				}
				
				self.standard_condition_info_list = condList;
				
				
				$('#dModal').modal('show');
			},

			updateInfoReady(index) {
				console.log(index);
				let self = this;
			
				let institution = self.institutionlist[index];
				console.log(index, institution);
			
				self.selectedIndex = index;
				self.institution = Object.assign({},institution);
				self.isLongTime = self.institution.effective_end_date=='2222-01-01' || self.institution.effective_end_date=='2099-12-31'	
				
				self.institution.effective_start_date = self.current_date;
				
				$('#iModal').modal('show');
			},
			
			//修改基本信息
			updateInfo() {
				let self = this;

				console.log('--- self.institution ->', self.institution)
				
				if (!self.institution.institution_name) {
					self.$toasted.error('制度名称不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.institution.effective_start_date) {
					self.$toasted.error('有效期开始日期不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.institution.effective_end_date) {
					self.$toasted.error('有效期结束日期不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.disabled = true;

				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.institution.dept_id,
					ins_id: self.institution.id,
					institution_name: self.institution.institution_name,
					effective_start_date: self.institution.effective_start_date,
					effective_end_date: self.institution.effective_end_date,
					effective: self.institution.effective?1:0,
					nonce_str: self.institution.nonce_str || '',
				}
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}

				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'updateInstitution', 
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;

						if (data.code == 200) {
							
							if (data.data.result_code == 'success') {
								//刷新修改的字段
								let _institution = data.data.institution;
								self.$set(self.institutionlist, self.selectedIndex, _institution);
								
								self.$toasted.success( '修改成功', {
									position: 'top-center',
									duration: '2000',
								})

								$('#iModal').modal('hide');
							}
							else {
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
							
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},
			
			removeReady() {
				let self = this;
				
				self.$confirm({
					title: '警告',
					message: '删除制度，已发放的制度额度都会被清除，不可再使用，确定删除？',
					button: {
						no: '取消',
						yes: '确定',
					},
					callback:(res)=>{
						console.log(res)
						if(res){
							self.remove();
						}
						
					}
				});
			
			},
			
			//删除制度
			remove() {
				let self = this;
			
				console.log('--- self.institution ->', self.institution)
				
				self.disabled = true;
			
				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.institution.dept_id,
					ins_id: self.institution.id,
					nonce_str: self.institution.nonce_str || '',
				}
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}
			
				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'delInstitution', 
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;
			
						if (data.code == 200) {
							
							if (data.data.result_code == 'success') {
								let _institution = data.data.institution;
								// self.$set(self.institutionlist, self.selectedIndex, _institution);
								
								self.institutionlist.splice(self.selectedIndex, 1)
								
								self.$toasted.success( '删除成功', {
									position: 'top-center',
									duration: '2000',
								})
								
								$('#iModal').modal('hide');
							}
							else {
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
							
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},
			
			onChangeLongTime(){
				let self = this;
				
				if(self.isLongTime){
					self.institution.effective_end_date = '2099-12-31'//生效结束日期为长期时，设为2099年
				}
				else{
					self.institution.effective_end_date = '';
				}
			},
			
			issueReady(index) {
				console.log(index);
				let self = this;
			
				let institution = self.institutionlist[index];
				console.log(index, institution);
				
				//方式1：用弹窗
				// self.selectedIndex = index;
				// self.institution = Object.assign({},institution);
				// self.isLongTime = self.institution.effective_end_date=='2222-01-01' || self.institution.effective_end_date=='2099-12-31'	
				
				// self.institution.effective_start_date = self.current_date;
				
				// $('#sModal').modal('show');
				
				//方式2：用独立页
				//必须 用 name，否则无法传参
				this.$router.push({ name: 'institution',  query: { id: institution.id, nonce_str: institution.nonce_str }})
			},
			
			
			importIssueReady(index) {
				console.log(index);
				let self = this;
			
				let institution = self.institutionlist[index];
				console.log(index, institution);
				
				//方式1：用弹窗
				// self.selectedIndex = index;
				// self.institution = Object.assign({},institution);
				// self.isLongTime = self.institution.effective_end_date=='2222-01-01' || self.institution.effective_end_date=='2099-12-31'	
				
				// self.institution.effective_start_date = self.current_date;
				
				// $('#sModal').modal('show');
				
				//方式2：用独立页
				//必须 用 name，否则无法传参
				this.$router.push({ name: 'importquotas',  query: { id: institution.id, nonce_str: institution.nonce_str }})
			},
			
			
			updateRemarkReady(index) {
				console.log(index);
				let self = this;
			
				let institution = self.institutionlist[index];
				console.log(index, institution);
				
				//方式1：用弹窗
				self.selectedIndex = index;
				self.institution = Object.assign({},institution);
				self.institution_remark = self.institution.remark || '';
				self.selected_category = self.institution.category || ''
				
				$('#sModal').modal('show');
			},
			
			updateRemark(){
				let self = this;

				console.log('--- self.institution_remark ->', self.institution_remark)
				
				self.disabled = true;

				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.institution.dept_id,
					ins_id: self.institution.id,
					institution_remark: self.institution_remark,
					category: self.selected_category,
					nonce_str: self.institution.nonce_str || '',
				}
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}

				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'updateInstitutionRemark', 
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;

						if (data.code == 200) {
							
							if (data.data.result_code == 'success') {
								//刷新修改的字段
								let _institution = data.data.institution;
								self.$set(self.institutionlist, self.selectedIndex, _institution);
								
								self.$toasted.success( '修改成功', {
									position: 'top-center',
									duration: '2000',
								})

								$('#sModal').modal('hide');
							}
							else {
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
							
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},
			
			////MCC
			updateMccReady(index) {
				console.log(index);
				let self = this;
			
				let institution = self.institutionlist[index];
				console.log(index, institution);
			
				self.selectedIndex = index;
				self.institution = Object.assign({},institution);
				
				//重置mcc_list >>初始化当前值
				self.mcc_list = JSON.parse(JSON.stringify(_MCC_LIST));
				
				//初始化当前值 start
				let _mcc = {};//当前MCC
				
				console.log('---- standard_condition_info_list ->',self.institution.standard_condition_info_list);
				let standard_condition_info_list = JSON.parse(self.institution.standard_condition_info_list);
				for(let i=0; i<standard_condition_info_list.length; i++){
					let standard_condition_info = standard_condition_info_list[i];
					if(standard_condition_info.rule_factor == 'MCC'){
						_mcc = JSON.parse(standard_condition_info.rule_value);
						break;
					}
				}
				console.log('---- _mcc ->', _mcc);
				
				//其它项选中时
				if(JSON.stringify(self.mcc) != JSON.stringify(_mcc)){
					//不限 取消选中
					self.mcc_list[0].checked = false;
					self.mcc_list[0].items[0].checked = false;
					self.mcc_list[0].show = false;
					
					for(let i=0; i<self.mcc_list.length; i++){
						self.mcc_list[i].disabled = false;//其它一级可选
						//检查一级
						if(self.isInMcc(1, self.mcc_list[i].value, _mcc)){
							self.mcc_list[i].checked = true;
							self.mcc_list[i].show = true;
						}
						
						//检查二级
						for(let j=0; j<self.mcc_list[i].items.length; j++){
							if(self.isInMcc(2, self.mcc_list[i].items[j].value, _mcc)){
								self.mcc_list[i].items[j].checked = true;
							}
						}
					}
				}
				
				//初始化当前值 end
				
				$('#mccModal').modal('show');
			},
			
			isInMcc(level, value, mcc){//value是类目代号，level为级别，mcc为当前mcc规则
				for(var key in mcc){
					console.log('--- key:'+key);
					console.log('--- value:'+mcc[key]);
					
					if(level == 1 && value == key){
						return true;
					}
					else if(level == 2){
						for(let j=0; j<mcc[key].length; j++){
							let submcc = mcc[key][j];
							if(value == submcc){
								return true;
							}
						}
					}
				}
				
				return false;
			},
			
			toggleSubMccList(index){
				let self = this;
				self.mcc_list[index].show = !self.mcc_list[index].show;
			},
			
			onChangeMcc(index){
				console.log('--- index @ onChangeMcc ->',index)
				let self = this;
				
				//当选中一级时，必须展开其下的二级，且默认二级选中不限
				//当取消选中一级时，必须取消期有下的二级选中状态
				if(self.mcc_list[index].checked){//一级选中
					self.mcc_list[index].show = true;//展开二级
					self.mcc_list[index].items[0].checked = true;//二级「不限」选中
				}
				else{//一级取消选中时
					// self.mcc_list[index].show = false;//可以不收起来，以便看清二级选中的
					for(let i=0; i<self.mcc_list[index].items.length; i++){
						self.mcc_list[index].items[i].checked = false;
					}
				}
				
				// 当一级不限选中，其它一级disabled，且取消选中以及其下二级也取消
				// 当一级不限取消选中，其它一级解除disabled
				if(index == 0){
					if(self.mcc_list[index].checked){
						for(let i=1; i<self.mcc_list.length; i++){
							self.mcc_list[i].checked = false;
							self.mcc_list[i].disabled = true;
							
							//二级处理：全取消选中，且disabled
							for(let j=0; j<self.mcc_list[i].items.length; j++){
								self.mcc_list[i].items[j].checked = false;
								self.mcc_list[i].items[j].disabled = true;
							}
						}
					}
					else{
						for(let i=1; i<self.mcc_list.length; i++){
							self.mcc_list[i].disabled = false;
						}
					}
				}
			},
			
			onChangeSubMcc(index, subindex){
				console.log('---  index, subindex @ onChangeSubMcc ->',index, subindex)
				let self = this;
				
				let current_submcc = self.mcc_list[index].items[subindex];//当前二级类目
				console.log('---  current_submcc ->',current_submcc)
				
				//I.选中
				//当选中二级「不限」项时，其它项要全取消
				//当选中二级其它项时，「不限」要取消掉
				if(current_submcc.checked){
					if(subindex == 0){//0表示选中不限
						//从第2个开始，全部设为false
						for(let i=1; i<self.mcc_list[index].items.length; i++){
							//self.mcc_list[index].items[i].checked = false;////vue UI刷新问题，需要用$set
							let submcc = self.mcc_list[index].items[i];
							submcc.checked = false;
							self.$set(self.mcc_list[index].items, i, submcc);
						}
					}
					else{
						// self.mcc_list[index].items[subindex].checked = true;//自己设为true？？？本来可以v-model自动设置
						current_submcc.checked = true;
						self.$set(self.mcc_list[index].items, subindex, current_submcc)
						self.mcc_list[index].items[0].checked = false;//不限设为false
					}
				}
				
				console.log('---- self.mcc_list[index].items[subindex] ->',self.mcc_list[index].items[subindex])
			},
			
			saveMcc(){
				let self = this;
				
				//设置一级类目与二级类目
				self.mcc = {};//初始化
				
				if(self.mcc_list[0].items[0].checked){//一级不限时
					self.mcc = {"-1": ["-1"]}//重置默认MCC
				}
				else{
					for(let i=1; i<self.mcc_list.length; i++){
						if(self.mcc_list[i].checked){//一级选中
							//二级处理：加入选中的
							let sub_mcc = [];
							for(let j=0; j<self.mcc_list[i].items.length; j++){
								if(self.mcc_list[i].items[j].checked){
									sub_mcc.push(self.mcc_list[i].items[j].value);
								}
							}
							self.mcc[self.mcc_list[i].value] = sub_mcc;
						}
					}
				}
				
				console.error('---- 保存 self.mcc ->', JSON.stringify(self.mcc));
				
				if(JSON.stringify(self.mcc) ==='{}'){
					self.$toasted.error('请指定商户类型.', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				// $('#mccModal').modal('hide');
				
				//TODO：调用修改使用规则接口
				self.disabled = true;
				
				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.institution.dept_id,
					ins_id: self.institution.id,
					
					mcc: JSON.stringify(self.mcc),
					
					nonce_str: self.institution.nonce_str || '',
				}
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}
				
				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'updateInstitutionMcc',
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						self.disabled = false;
				
						if (data.code == 200) {
							
							if (data.data.result_code == 'success') {
								//刷新修改的字段
								let _institution = data.data.institution;
								self.$set(self.institutionlist, self.selectedIndex, _institution);
								
								self.$toasted.success( '修改成功', {
									position: 'top-center',
									duration: '2000',
								})
				
								$('#mccModal').modal('hide');
							}
							else {
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
							
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
				
			},
		},
	}
</script>

<style>
	.mx-icon-calendar,
	.mx-icon-clear {
		right: 28px !important;
	}
	.text-ellipsis{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
